/*******************
* General
*******************/
.app__infos {
  width: auto;
  border-bottom: 1px solid #DDE2E7;
}

.app__infos:nth-child(2n) {
  background-color: #f4f8f9;
}

.app__infos > .app__infos__content {
  width: 1120px;
  margin: 0 auto;
}

/*******************
* Hero
*******************/
.hero {
  display: flex;
  padding: 50px 0;
}

.hero__header {
  margin-right: 40px;
  width: 580px;
}

.hero__header__title {
  color: #2C5378;
  margin-bottom: 0;
}

.hero__header__baseline {
  color: #BFBFBF;
  font-size: 16px;
  line-height: 1.3em;
}

.hero__picture {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 500px;
  margin: 0;
}

.hero__picture__img {
  width: 100%;
  height: auto;
}

/*******************
* Assets
*******************/
.assets {
  position: relative;
  padding-bottom: 50px;
}

.assets__nav__list {
  display: flex;
  justify-content: center;
  list-style: none;
  padding-left: 0;
}

.assets__nav__list__item {
  margin: 0 20px;
}

.assets__title {
  color: #2C5378;
  margin-bottom: 60px;
  text-align: center;
}

.assets__categories {
  display: flex;
  align-items: center;
  justify-content: center;
}

.assets__item {
  border-radius: 5px;
  background-color: #fff;
  border: 1px solid #DDE2E7;
  width: 300px;
  overflow: hidden;
  transition: box-shadow linear 300ms;
}

.assets__item:hover {
  position: relative;
  z-index: 1;
  box-shadow: 0 0 5px rgba(0, 0, 0, .25);
}

.assets__item:first-child {
  margin-right: 10px;
}

.assets__item:last-child {
  margin-left: 10px;
}

.assets__item__header {
  background-color: #2C5378;
  padding: 20px 10px;
}

.assets__item__title {
  color: #fff;
  font-size: 16px;
  text-align: center;
  margin-bottom: 0;
}

.assets__item__list {
  list-style: none;
  margin-bottom: 0;
  padding: 20px 25px;
}

.assets__item__list__item {
  border-bottom: 1px solid #DDE2E7;
  font-size: 13px;
  text-align: center;
  padding: 10px 0;
}

.assets__item__list__item:first-child {
  padding-top: 0;
}

.assets__item__list__item:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

/*******************
* Clients
*******************/
.clients {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.clients__title {
  color: #2C5378;
  text-align: center;
  margin-bottom: 40px;
  width: 100%;
}

.clients__list {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  padding-left: 0;
  width: 940px;
  margin: 0 auto;
  flex-wrap: wrap;
}

.clients__list__item {
  position: relative;
  margin: 0 20px 25px;
}

.clients__list__item__icon {
  position: absolute;
  top: -7px;
  left: -14px;
  background-color: #2C5378;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px; height: 38px;
}

.clients__list__item__icon > img {
  width: 24px;
  height: auto;
}

.clients__list__item__title {
  border: 3px;
  background-color: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, .25);
  padding: 5px 5px 5px 30px;
  display: block;
  text-align: center;
  width: 160px;
  white-space: nowrap;
}

.clients__content {
  display: flex;
  flex-direction: column;
  width: 300px;
  margin-top: 40px;
}

.clients__map {
  display: block;
  margin-right: 20px;
  width: 800px; height: auto;
}

.clients__content ol {
  margin-top: auto;
  padding-left: 10px;
}

.clients__content li {
  font-size: .75em;
  font-style: italic;
}

.clients__content strong {
  color: #2C5378;
}

/*******************
* Contact
*******************/
.contacts {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
  justify-content: center;
}

.contacts__title {
  color: #2C5378;
  text-align: center;
  width: 100%;
  margin-bottom: 30px;
}

.contacts__item {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: center;
  border-right: 1px solid #DDE2E7;
  padding: 20px;
  width: calc(24% - 40px);
}

.contacts__item:last-child {
  border-right: none;
}

.contacts__item__header {
  margin-bottom: 20px;
  text-align: center;
  width: 100%;
}

.contacts__item__content {
  margin-bottom: 0;
}

.contacts__item__logo {
  margin-bottom: 10px;
  width: 32px;
  height: 32px;
}

.contacts__item__title {
  display: block;
  font-size: 16px;
}

.contacts__item__header__link {
  color: #213337;
  text-decoration: underline;
}

.app__issuemanager {
  margin-bottom: 0;
  margin-left: auto;
}

.app__issuemanager__logo {
  width: 18px;
  height: 18px;
}

.contacts__item--address .contacts__item__content {
  margin-left: 5px;
}


/*******************
* Businesses
*******************/
.businesses {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.businesses__title {
  color: #2C5378;
  text-align: center;
  margin-bottom: 30px;
  width: 100%;
}

.businesses__nav {
  margin-bottom: 30px;
}

.businesses__list {
  position: relative;
  list-style: none;
  margin-bottom: 20px;
  padding-left: 0;
  width: 100px; height: 100px;
  margin: 180px;
}

.businesses__item {
  position: absolute;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid #fff;
  padding: 10px;
  width: 80px; height: 80px;
  transition: box-shadow linear 300ms;
}

.businesses__item--selected {
  border-color: #2C5378;
}

.businesses__item--selected .businesses__item__label {
  color: #2C5378;
}

.businesses__item--main {
  top: 50%;
  left: 50%;
  margin-left: -50px;
  margin-top: -50px;
  box-shadow: none;
}

.businesses__item--previous {
  display: none;
}

.businesses__item--next {
  display: none;
}

.businesses__item__link {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: visible;
  width: 100%; height: 100%;
}

.businesses__item:not(.businesses__item--main):hover {
  box-shadow: 0 0 5px rgba(0, 0, 0, .25);
}

.businesses__item:after {
  content: "";
  width: 45%;
  border: 1px dashed #D9D9D9;
  top: 50%;
  right: -60%;
  position: absolute;
  z-index: 0;
}

.businesses__item--main:after {
  display: none;
}

.businesses__item:nth-child(2) {
  transform: rotate(0deg) translateX(-170px);
}

.businesses__item:nth-child(3) {
  transform: rotate(40deg) translateX(-170px);
}

.businesses__item:nth-child(3) > .businesses__item__link {
  transform: rotate(-40deg);
}

.businesses__item:nth-child(4) {
  transform: rotate(80deg) translateX(-170px);
}

.businesses__item:nth-child(4) > .businesses__item__link {
  transform: rotate(-80deg);
}

.businesses__item:nth-child(5) {
  transform: rotate(120deg) translateX(-170px);
}

.businesses__item:nth-child(5) > .businesses__item__link {
  transform: rotate(-120deg);
}

.businesses__item:nth-child(6) {
  transform: rotate(160deg) translateX(-170px);
}

.businesses__item:nth-child(6) > .businesses__item__link {
  transform: rotate(-160deg);
}

.businesses__item:nth-child(7) {
  transform: rotate(200deg) translateX(-170px);
}

.businesses__item:nth-child(7) > .businesses__item__link {
  transform: rotate(-200deg);
}

.businesses__item:nth-child(8) {
  transform: rotate(240deg) translateX(-170px);
}

.businesses__item:nth-child(8) > .businesses__item__link {
  transform: rotate(-240deg);
}

.businesses__item:nth-child(9) {
  transform: rotate(280deg) translateX(-170px);
}

.businesses__item:nth-child(9) > .businesses__item__link {
  transform: rotate(-280deg);
}

.businesses__item:nth-child(10) {
  transform: rotate(320deg) translateX(-170px);
}

.businesses__item:nth-child(10) > .businesses__item__link {
  transform: rotate(-320deg);
}

.businesses__item__icon {
  margin-bottom: 10px;
  width: 26px; height: 26px;
}

.businesses__item__label {
  color: #BFBFBF;
  font-size: 14px;
  line-height: 1.2em;
  text-align: center;
}

.businesses__item--main .businesses__item__icon {
  width: 50px; height: 50px;
}

.businesses__content {
  flex: 1;
  padding-left: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.businesses__description {
  display: none;
  opacity: 0;
  line-height: 1.2em;
  transform: opacity linear 300ms;
}

.businesses__description li {
  margin-bottom: 5px;
}

.businesses__description--activated {
  display: block;
  opacity: 1;
}

.businesses__description__title {
  color: #2C5378;
  font-size: 17px;
}

/*******************
* Services
*******************/
.services {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.services__title {
  color: #2C5378;
  text-align: center;
  margin-bottom: 30px;
  width: 100%;
}

.services__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.services__item {
  box-shadow: 0 0 5px rgba(0, 0, 0, .25);
  border-top: 3px solid #2C5378;
  background-color: #fff;
  padding: 20px;
  margin-bottom: 20px;
  width: 29%;
}

.services__item__title {
  color: #2C5378;
  font-size: 14px;
  text-align: center;
}

@media (max-device-width: 1024px) {
  .app__infos > .app__infos__content {
    width: 100%;
  }

  .hero {
    flex-shrink: unset;
    flex-grow: unset;
  }

  .hero__header {
    width: 440px;
  }

  .hero__picture {
    width: 400px;
  }

  .clients__map {
    width: 500px; 
  }

  .businesses {
    justify-content: center;
  }

  .services__item {
    width: 45%;
  }

  .app__nav--footer {
    width: 100%;
  }

  .app__nav--footer .app__nav__list {
    width: 100%;
    flex-direction: column;
  }

  .app__nav--footer .app__nav__list .app__nav__item {
    margin: 0;
  }
}

@media (max-device-width: 768px) {
  .clients__map {
    width: 350px; 
  }

  .contacts__item {
    width: calc(30% - 40px);
  }

  .businesses__content {
    flex: auto;
    width: 100%;
  }

  .services__item {
    width: 42%;
  }
}

@media (max-device-width: 812px) and (orientation: landscape) {
  .services__item {
    width: 42%;
  }
}

@media (max-device-width: 667px) and (orientation: portrait) {
  .app__infos--hero {
    padding-top: 0;
  }

  .hero {
    padding: 0;
  }

  .hero {
    flex-direction: column;
  }

  .hero__header {
    margin-right: 0;
    margin-bottom: 20px;
    width: 100%;
  }

  .hero__picture {
    width: 100%;
  }

  .businesses__item {
    display: none;
  }

  .businesses__nav {
    width: 100%;
  }

  .businesses__list {
    display: flex;
    justify-content: flex-end;
    margin: 0 0 10px;
    width: 100%; height: auto;
  }

  .businesses__item--previous,
  .businesses__item--next {
    position: relative;
    top: auto; left: auto;
    display: block;
    margin: 0;
    background-color: #2C5378;
    border-color: #2C5378;
    width: 12px; height: 12px;
  }

  .businesses__item--previous {
    margin-right: 10px;
  }

  .businesses__item--previous .businesses__item__link::before,
  .businesses__item--previous .businesses__item__link::after,
  .businesses__item--next .businesses__item__link::before,
  .businesses__item--next .businesses__item__link::after {
    position: absolute;
    content: "";
    background-color: #fff;
    transform-origin: center;
    width: 12px; height: 3px;
  }

  .businesses__item--previous .businesses__item__link::before,
  .businesses__item--next .businesses__item__link::after {
    transform: rotate(-45deg);
  }

  .businesses__item--previous .businesses__item__link::after,
  .businesses__item--next .businesses__item__link::before {
    
    transform: rotate(45deg);
  }

  .businesses__item--previous .businesses__item__link::before,
  .businesses__item--next .businesses__item__link::before {
    top: 11px;
  }

  .businesses__item--previous .businesses__item__link::after,
  .businesses__item--next .businesses__item__link::after {
    bottom: 11px;
  }

  .businesses__item--previous::after,  
  .businesses__item--next::after {
    display: none;
  }

  .businesses__content {
    padding-left: 0;
  }

  .businesses__description {
    margin-bottom: 20px;
  }

  .assets__categories {
    flex-wrap: wrap;
  }

  .assets__item {
    margin-bottom: 20px;
  }

  .assets__item:first-child {
    margin-right: 0;
  }

  .assets__item:last-child {
    margin-left: 0;
    margin-bottom: 0;
  }

  .clients {
    flex-direction: column;
  }

  .clients__list {
    width: 100%;
    margin-bottom: 0;
  }

  .clients__list__item {
    margin-bottom: 40px;
  }

  .clients__map {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }

  .contacts {
    flex-direction: column;
    align-items: center;
  }

  .contacts__item {
    border-right: none;
    border-bottom: 1px solid #DDE2E7;
    width: calc(100% - 20px);
  }

  .contacts__item:last-child {
    border-bottom: none;
  }

  .services__item {
    width: 100%;
  }
}
